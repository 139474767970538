<template>
  <div>
    <div>
      <div class="operationControl personalSpecialOrder">
        <div class="searchbox">
          <el-popover
            placement="bottom"
            width="100">
              <div class="flexac">
                <el-button type="text" @click="downloadList(1)" :disabled="!projectId">xls</el-button>
                <el-button type="text" @click="downloadList(2)" :disabled="!projectId && !allowPrint">pdf</el-button>
              </div>
            <el-button class="bgc-bv" round slot="reference">签到信息</el-button>
          </el-popover>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
            style="width: 100%" stripe :header-cell-style="tableHeader">
            <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
            <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="100px" />
            <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" width="150px" />
            <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" width="150px" />
            <el-table-column label="签到日期" align="left" prop="createTime">
              <template slot-scope="scope">
                {{ scope.row.createTime }}
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "ciq_type_2",
  components: {
    Empty,
    PageNum,
  },
  props: {
    // 班级
    projectId: {
      type: String,
      default: "",
    },
    // 学员
    userId: {
      type: String,
      default: "",
    },
    // tap选项卡
    tabs: {
      type: String,
      default: "",
    },
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      // 是否可以下载
      allowPrint: false,
    };
  },
  watch: {
    // 监听 - tap选项卡
    tabs: {
      handler: function (val) {
        if (val == "type_2" && this.projectId) {
          this.getData(-1);
        }
      },  
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  mounted() { },
  created() { },
  methods: {
    // 查询 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        userId: this.userId
      };
      this.doFetch(
        {
          url: "/biz/project/getUserCheckInInfo",
          params,
          pageNum,
        },
      );
      this.get_allow_print();
    },
    // 获取 - 是否可以下载
    get_allow_print() {
      this.$post("/biz/project/getProjectInfo", { projectId: this.projectId }).then((res) => {
        this.allowPrint = res.data.allowPrint;
      });
    },
    // 导出 - 列表数据
    downloadList(type) {
      let cData = {
        projectId: this.projectId
      }
      // 导出 - 签到信息(xls)
      if (type == 1) {
        cData.downloadFileType = '10';
        cData.identifier = "SHANGHAI_E20";
        cData.cached = false;
      }
      // 导出 - 签到信息(pdf)
      if (type == 2) {
        cData.downloadFileType = '20';
        cData.identifier = "SHANGHAI_E20";
      }
      this.$post("/sys/download/shanghai/api", cData).then((res) => {
        if (res.status == '0') {
          for (let item of res.data) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.personalSpecialOrder {
  border-bottom: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
</style>
