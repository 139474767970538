<template>
  <div>
    <div class="operationControl personalSpecialOrder">
      <div class="searchbox">
        <el-popover
            placement="bottom"
            width="100">
              <div class="flexac">
                <el-button type="text" @click="downloadList(1)" :disabled="!projectId">xls</el-button>
                <el-button type="text" @click="downloadList(2)" :disabled="!projectId && !allowPrint">pdf</el-button>
              </div>
            <el-button class="bgc-bv" round slot="reference">下载答疑记录</el-button>
          </el-popover>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <div :style="'max-height:' + tableHeight + 'px'">
          <template v-if="listData.length">
            <el-container v-for="(item, index) in listData" :key="index">
              <el-aside width="200px">
                <img class="timg" v-if="item.userHeadUrl" :src="item.userHeadUrl" alt="" />
                <img class="timg" v-else src="../../../assets/headpath.png" alt="" />
                <p class="t">{{ item.userName }}</p>
                <p class="ttype ttype_1">学员</p>
              </el-aside>
              <el-main>
                <div class="content">
                  {{ item.content }}
                </div>
                <div class="operation">
                  <img class="jb" v-if="item.reportNum > Number(0)" src="../../../assets/jinggao.png" alt="" />
                  <span class="sj">{{ item.createTime }}</span>
                  <span class="hf" @click="oneCommentReply(item.teacherReplyList.length, item.show, index)"> {{
                    item.show ? '收起回复' : '回复(' + item.teacherReplyList.length + ')' }} </span>
                </div>
                <el-collapse-transition>
                  <div class="replyContent" v-show="item.show">
                    <div class="pl" v-for="(item1, index1) in item.teacherReplyList" :key="index1">
                      <img class="tx" v-if="item1.userHeadUrl" :src="item1.userHeadUrl" alt="" />
                      <img class="tx" v-else src="../../../assets/headpath.png" alt="" />
                      <div class="nr">{{ item1.content }}</div>
                      <div class="ttype ttype_1" v-if="item1.contentOrigin == 10">学员</div>
                      <div class="ttype ttype_2" v-else-if="item1.contentOrigin == 20">授课老师</div>
                      <div class="ttype ttype_3" v-else-if="item1.contentOrigin == 30">安知平台</div>
                      <div class="ttype ttype_4" v-else>- -</div>
                      <div class="sj">{{ item1.createTime }}</div>
                    </div>
                  </div>
                </el-collapse-transition>
              </el-main>
            </el-container>
          </template>
          <Empty v-if="!listData.length" slot="empty" />
        </div>
      </div>
    </div>
    <div class="pageNum" v-if="listData.length">
      <el-pagination :total="fenYe.total" :page-size="fenYe.size" :current-page="fenYe.currentPage" background
        :page-sizes="[10, 20, 30, 40, 50]" layout="total, sizes,prev, pager, next,jumper" @size-change="handleSizeChange"
        @current-change="handleCurrentChange"></el-pagination>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import { mapGetters } from "vuex";
export default {
  name: "ciq_type_3",
  components: {
    Empty
  },
  props: {
    // 班级
    projectId: {
      type: String,
      default: "",
    },
    // 学员
    userId: {
      type: String,
      default: "",
    },
    // tap选项卡
    tabs: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 是否可以下载
      allowPrint: false,
      // 列表数据
      listData: [],
      // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 10,
        total: null, // 共多少页
      },
      // 页面高度
      tableHeight: 450,
    };
  },
  watch: {
    // 监听 - tap选项卡
    tabs: {
      handler: function (val) {
        if (val == "type_4" && this.projectId) {
          this.getData(this.fenYe.currentPage, this.fenYe.size);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  mounted() {
    this.resizeRefresh();
  },
  created() {
    
  },
  methods: {
    // 每页多少条
    handleSizeChange(val) {
      this.fenYe.size = val;
      this.fenYe.currentPage = 1;
      this.getData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 当前页数
    handleCurrentChange(val) {
      this.fenYe.currentPage = val;
      this.getData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 初始化获取评论列表
    getData(page = 1, pagesize = this.fenYe.size) {
      const params = {
        projectId: this.projectId,
        userId: this.userId,
        pageNum: page,
        pageSize: pagesize,
      };
      this.$post("/biz/project/getUserAnsweringQuestion", params)
        .then((res) => {
          for (let i = 0; i < res.data.list.length; i++) {
            res.data.list[i].show = true;
            res.data.list[i].currentPage = 1;
          }
          this.listData = res.data.list;
          this.fenYe.total = res.data.total;
          this.get_allow_print();
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 是否可以下载
    get_allow_print() {
      this.$post("/biz/project/getProjectInfo", { projectId: this.projectId }).then((res) => {
        this.allowPrint = res.data.allowPrint;
      });
    },
    // 导出 - 列表数据
    downloadList(type) {
      let cData = {
        projectId: this.projectId
      }
      // 导出 - 下载答疑记录(xls)
      if (type == 1) {
        cData.downloadFileType = '10';
        cData.identifier = "SHANGHAI_E40";
        cData.cached = false;
      }
      // 导出 - 下载答疑记录(pdf)
      if (type == 2) {
        cData.downloadFileType = '20';
        cData.identifier = "SHANGHAI_E40";
      }
      this.$post("/sys/download/shanghai/api", cData).then((res) => {
        if (res.status == '0') {
          for (let item of res.data) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
    // resize 变化刷新页面
    resizeRefresh() {
      const self = this;
      self.getTableHeight();
      window.addEventListener("resize", function () {
        self.getTableHeight();
      });
    },
    // 页面内容高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 4;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    // 一级评论 - 展开回复
    oneCommentReply(floorReplyTotalNum, show, index) {
      if (floorReplyTotalNum > 0) {
        this.listData[index].show = !show;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.ttype_1 {
  background: #67c23a;
  color: #fff;
}

.ttype_2 {
  background: #e6a23c;
  color: #fff;
}

.ttype_3 {
  background: #409eff;
  color: #fff;
}

.ttype_4 {
  background: #F56C6C;
  color: #fff;
}

.ovy-a>div {
  overflow-y: scroll;
  min-height: 300px;
  &::-webkit-scrollbar { 
    width: 0 !important 
  }
}

.personalSpecialOrder {
  border-bottom: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.el-container {
  min-height: 160px;
  overflow: hidden;
  border-bottom: 1px solid #eee;
  margin-bottom: 14px;

  &:last-child {
    border-bottom: 0;
  }

  .el-aside {
    width: 200px;
    background: #f9f9f9;
    padding: 20px 0;
    float: left;

    .timg {
      width: 80px;
      height: 80px;
      display: block;
      margin: auto;
      border-radius: 50%;
    }

    .t {
      width: 100px;
      margin: auto;
      text-align: center;
      margin-top: 10px;
    }

    .ttype {
      width: 70px;
      margin: auto;
      text-align: center;
      margin-top: 4px;
      font-size: 12px;
      border-radius: 4px;
      height: 22px;
      line-height: 22px;
    }
  }

  .el-main {
    width: 80%;
    height: 100%;
    float: left;
    padding-bottom: 6px;

    .content {
      min-height: 110px;
    }

    .operation {
      text-align: right;
      height: 25px;
      line-height: 25px;

      //   background: #f7f7f7;
      .jb {
        width: 16px;
        vertical-align: middle;
        cursor: pointer;
      }

      .sj {
        color: #909090;
        padding: 0 5px;
        font-size: 12px;
      }

      .sc {
        padding: 0 5px;
        cursor: pointer;

        &:hover {
          color: red;
        }
      }

      .syj {
        padding: 0 5px;
        color: #909090;
        font-size: 12px;
        cursor: pointer;

        &:hover {
          color: #4089fa;
        }
      }

      .hf {
        color: #4089fa;
        padding: 0 5px;
        cursor: pointer;
        font-size: 12px;
        background: #f7f7f7;
      }
    }

    .replyContent {
      overflow: hidden;
      background: #f7f7f7;
      padding: 10px 20px;

      .pl {
        clear: both;
        border-bottom: 1px solid #f1f1f1;
        margin-bottom: 6px;
        overflow: hidden;

        .tx {
          width: 30px;
          height: 30px;
          float: left;
          margin-right: 10px;
          border-radius: 50%;
        }

        .hxm {
          font-weight: 6000;
          float: left;
          height: 25px;
          line-height: 20px;
          margin-top: 5px;
          margin-right: 5px;
          font-size: 12px;
        }

        .xm {
          font-weight: 6000;
          float: left;
          height: 25px;
          line-height: 20px;
          margin-top: 5px;
          margin-right: 5px;
          font-size: 12px;
        }

        .nr {
          float: left;
          line-height: 20px;
          margin-top: 5px;
          font-size: 12px;
        }

        .sj {
          float: right;
          height: 25px;
          line-height: 20px;
          margin-top: 5px;
          margin-right: 5px;
          color: #909090;
          font-size: 12px;
        }

        .ttype {
          width: 60px;
          text-align: center;
          float: right;
          height: 20px;
          line-height: 20px;
          padding: 0 4px;
          font-size: 12px;
          border-radius: 4px;
          margin-top: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}</style>
